.mainHeader {
    height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}
.mainHeader::before {
    background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%), url("../../assets/galaxy-header.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    z-index: 1;
    opacity: .8;
    box-shadow: inset 0 10px 10px -10px #000000;
}
    .mainHeader__title {
        margin: 0;
        font-weight: lighter;
        z-index: 2;
        position: relative;
        color: #FFFFFFCC;
        animation: TitlePresentation 2s ease-in-out;
    }
    .mainHeader__subtitle {
        font-weight: lighter;
        animation: TitlePresentation 2s ease-in-out;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        opacity: 0;
        z-index: 2;
        color: #ffffff77;
        transform: translateY(50px);
    }

    @keyframes TitlePresentation {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }
        80% {
            opacity: .8;
            transform: translateY(-10px);
        }
        90% {
            opacity: .9;
            transform: translateY(5px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
.desktopNav {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #00000055;
    backdrop-filter: blur(2px);
}
.desktopNav__list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}
.desktopNav__item {
    opacity: 0;
    margin-inline: 10px;
    animation: DesktopNavPresentation 1s ease-in-out forwards;
    cursor: pointer;
}
.desktopNav__item:nth-child(1) {
    animation-delay: 1s;
}
.desktopNav__item:nth-child(2) {
    animation-delay: 1.2s;
}
.desktopNav__item:nth-child(3) {
    animation-delay: 1.4s;
}
.desktopNav__item:nth-child(4) {
    animation-delay: 1.6s;
}
.desktopNav__item:nth-child(5) {
    animation-delay: 1.8s;
}

.desktopNav__item--label {
    border: solid 2px transparent;
}

@keyframes DesktopNavPresentation {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    80% {
        opacity: .8;
        transform: translateY(-10px);
    }
    90% {
        opacity: .9;
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (min-width: 768px) {
    .mainHeader__title {
        font-size: 3rem;
    }
    .mainHeader__subtitle {
        font-size: 1.3rem;
        margin-top: 5px;
    }
    .desktopNav__item--label:hover{
        border-bottom: solid 2px #540075;
    }
}
