.textImageCard {
    padding: 20px;
}

.textImageCard__image {
    margin: 0;
    opacity: 0;
    animation: PresentationFromLeft 1s ease-in-out;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    max-width: 400px;
    display: block;
    margin-inline: auto;
}

.textImageCard__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.textImageCard__image > figcaption {
    display: none;
}

.textImageCard__text {
}

.textImageCard__title {
    opacity: 0;
    animation: PresentationFromLeft 1s ease-in-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    font-weight: normal;
}

.textImageCard__paragraph {
    font-weight: normal;
    opacity: 0;
    animation: PresentationFromLeft 1s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    color: #FFFFFFCC;
}

.textCard {
    padding-top: 20px;
}
.textCard__title {
    font-weight: normal;
}
.textCard__contents {}
.textCard__paragraph {
    font-weight: normal;
    opacity: 0;
    animation: PresentationFromLeft 1s ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;
    color: #FFFFFFCC;
    margin: 5px;
}
.servicos__item {
    display: flex;
    font-size: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(88, 10, 101, 0.61);
    padding: 20px;
    margin-inline: 20px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-weight: bold;
}

.listCard {
    padding-block: 20px;
}
.listCard__header {}
.listCard__header > h2 {
    font-weight: normal;
}
.listCard__header > p {
    color: #FFFFFFCC;
}
.listCard__listContents {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}
.listCard__listItem {
    background: #000;
    border-radius: 15px;
    margin-inline: 10px;
    padding-bottom: 10px;
    min-width: 230px;
    width: 230px;
    height: 230px;
}
.listCard__listItem:nth-child(1) {
    margin-left: 0;
}
.listCard__listItem:nth-last-child(1) {
    margin-right: 0;
}
.listCard__figure {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 90%;
}
.listCard__figure > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
}
.listCard__figure > div > img {
    width: 150px;
    animation: PulseLogo 2s infinite alternate ease-in-out;
}
.listCard__figure > figcaption {}
.figureShadow {
    background: radial-gradient(#FFFFFF55, transparent 60%);
    height: 20px;
    width: 100%;
    border-radius: 15px;
    position: absolute;
    bottom: 0;
    transition: .5s;
    animation: PulseShadow 2s infinite alternate ease-in-out;
}
.textCard__servicos--itens {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

@keyframes PulseShadow {
    0% {
        width: 90%;
    }
    100% {
        width: 40%;
    }
}

@keyframes PulseLogo {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

@media (min-width: 768px) {
    .textImageCard {
        display: flex;
    }

    .textImageCard__text {
        flex-basis: 50%;
    }

    .textImageCard__image {
        flex-basis: 50%;
    }
    .textImageCard--variation {
        flex-direction: row-reverse;
        background-color: rgba(34, 4, 38, 0.55);
    }
    .listCard__listContents {
        overflow-x: visible;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        padding-inline: 150px;
        margin-top: 60px;
    }
    .listCard__listItem {
        box-shadow: rgba(0, 0, 0, 0.17) 0 48px 100px 0;
    }
    .servicos {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cuidadosParaVoceCard {
        margin-top: 60px;
    }
    .cuidadosParaVoceCard, .tecnologiasCard {
        padding: 20px;
        background-color: rgba(34, 4, 38, 0.55);
        width: 100%;
    }
    .tecnologiasCard {
        margin-bottom: 50px;
        padding-bottom: 50px;
    }
}
