.hamburgerButton {
    position: absolute;
    width: 20px;
    height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    overflow: hidden;
    z-index: 11;
}
.hamburgerButton__box {
    width: 100%;
    height: 30px;
    background: rgba(60, 0, 70, 0.3);
    position: fixed;
    top: 0;
    z-index: 11;
    backdrop-filter: blur(2px);
}
    .hamburgerButton__line {
        height: 2px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        opacity: 0;
        transform: rotate(0deg) translate(0px, 0px);
        transition: .5s;
    }
    .hamburgerButton__line__clicked {
        transition: .5s;
    }
    .hamburgerButton__line__clicked:nth-child(1) {
        opacity: 0;
    }
    .hamburgerButton__line__clicked:nth-child(2) {
        transform: rotate(45deg) translate(0px, -1px);
        transition: .5s;
    }
    .hamburgerButton__line__clicked:nth-child(3) {
        transform: rotate(-45deg) translate(4px, -2px);
        transition: .5s;
    }
    .hamburgerButton__line:nth-child(1) {
        animation: PresentationFromRight 1s ease-in-out;
        animation-fill-mode: forwards;
    }
    .hamburgerButton__line:nth-child(2) {
        animation: PresentationFromRight 1s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: .5s;
    }
    .hamburgerButton__line:nth-child(3) {
        animation: PresentationFromRight 1s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 1s;
    }

.hamburgerButton__menu {
    position: fixed;
    top: 0;
    right: 0;
    background: var(--wardie-gradient);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.62);
    z-index: 999;
    height: 100vh;
    overflow: hidden;
    width: 0;
    transition: .5s;
}
.hamburgerButton__menu--clicked {
    width: 50%;
    transition: .5s;
    z-index: 999;
}
.hamburgerButton__menu--shadow {
    z-index: 99;
    width: 100vw;
    min-height: 100vh;
    background: #000;
    opacity: .5;
    position: absolute;
    top: 0;
}
.hamburgerButton__menu__list {
    margin: 0;
    padding: 5px 20px;
    list-style: none;
    font-weight: normal;
}
.hamburgerButton__menu__list__item {
    white-space: nowrap;
}