.mainFooter {
    padding-bottom: 100px;
    margin-top: 30px;
    padding-inline: 20px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.77) 0%, transparent 100%), url(http://localhost:3000/static/media/galaxy-header.185058d….png);
}
    .mainFooter__text {
        text-align: center;
        font-weight: lighter;
        margin-top: 30px;
        color: rgba(204, 204, 204, 0.54);
    }
    .mainFooter__social {
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 20px;
    }
        .mainFooter__link {
            color: #fff;
            text-decoration: none;
            font-weight: lighter;
        }
        .mainFooter__link > img {
            width: 30px;
            margin-inline: 10px;
        }

.mainFooter__social--contato {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}
    .mainFooter__social--contato > p {
        margin: 0;
    }


@media (min-width: 768px) {
    .mainFooter {
        padding-inline: 100px;
        padding-block: 20px;
    }
    .mainFooter__text {
        align-items: start;
        margin-top: 60px;
    }
    .mainFooter__social {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 20px;
    }
    .mainFooter__social--contato {
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        background-color: #2a032f;
        padding: 8px;
        border-radius: 10px;
    }
    .mainFooter__contato--img {
        width: 25px;
        border-radius: 50%;
        margin-inline: 10px;
    }
}
