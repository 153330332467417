.app__main {}
.articlesSection__main {
    padding-inline: 20px;
}

@media (min-width: 768px) {
    .articlesSection__main {
        padding-inline: 100px;
    }
}


