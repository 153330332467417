.bottom-appbar {
    height: 70px;
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 1;
    margin-inline: 10px;
    .tabs {
        display: flex;
        flex-direction: row;
        height: 100%;
        .tab {
            &--left {
                width: 100%;
                border-radius: 10px 30px 0 10px;
            }
            &--right {
                width: 100%;
                border-radius: 30px 10px 10px 0;
            }
            &--fab {
                width: 190px;
                height: 100%;
                background: transparent;
                border: none;
                border-bottom: 1px solid #580a65;
                display: flex;
                overflow-y: hidden;
                .top {
                    width: calc(100% + 2px);
                    height: 50%;
                    border-bottom-left-radius: 100px;
                    border-bottom-right-radius: 100px;
                    background-color: transparent;
                    box-shadow: 0 30px 0 25px #580a65;
                    display: flex;
                }
            }
            background-color: #580a65;
            width: 33.4%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-top: 1px solid #580a65;
            box-shadow: 1x 1x 3px #ccc, -1px -1px 3px #ccc;
            font-size: 24px;
            span {
                font-size: 17px;
            }
            i {
                font-size: 24px;
            }
        }
        .fab {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 70px;
            font-weight: bold;
            font-size: 22px;
            color: #fff;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -55%);
            overflow: hidden;
        }
    }
}
.fab__logo {
    position: relative;
}
.fab__logo > img {
    width: 70px;
    border-radius: 100px;
    display: block;
    animation: LogoPresentation 1s ease-in-out;
}
.fab__logo > figcaption {
    display: none;
}
.fab__logoLight {
    width: 200px;
    height: 20px;
    background: #fff;
    position: absolute;
    opacity: .2;
    transform: rotate(45deg) translate(-50%, 500%);
    animation: LightLogo 1s ease-in-out;
    animation-delay: 5s;
}

@keyframes LogoPresentation {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes LightLogo {
    0% {
        transform: rotate(45deg) translate(-50%, 400%);
    }
    100% {
        transform: rotate(45deg) translate(-50%, -300%);
    }
}